import { updatedTask, createTaskOffer, copyOffer } from '@/cruds/task.crud';
import moment from 'moment';
import axios from 'axios';
import { isNil, capitalize, generateRandomFilename } from '~utils';
// import { apolloClient as apollo } from "@/vue-apollo";
// import gql from 'graphql-tag'
import GlobalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { acceptedOffer } from '@/cruds/task.crud';
import dbExtension from 'mime-db';

const EMITTER_RELOAD_DATA = 'offer-action:refresh';

export default {
	name: 'OffersMixin',

	mixins: [GlobalMixin, languageMessages],

	computed: {
		isOffer() {
			return this.$route.name === 'offers' || this.$route.name === 'administrationOffers';
		},
		offerFields() {
			const fields = [
				{
					key: 'offerNumber',
					label: this.FormMSG(22, 'N°'),
					formatter: (v) => v,
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'orderNumber',
					label: this.FormMSG(23, 'N° Order'),
					formatter: (v) => v,
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'name',
					label: this.FormMSG(6, 'Name'),
					formatter: (value) => {
						return capitalize(value);
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true
				},
				{
					key: 'client.name',
					label: this.FormMSG(10, 'Client'),
					sortable: true
				},
				{
					key: 'offerDate',
					label: this.FormMSG(1003, 'Offer Date'),
					sortable: true,
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY') : '-';
					},
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'location',
					label: this.FormMSG(63, 'Location'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v
				},
				{
					key: 'sendStatus',
					label: this.FormMSG(1013, 'Status'),
					formatter: (value) => {
						const findIndex = this.sendStatues.findIndex((s) => s.value === value);
						return this.sendStatues[findIndex].text;
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'percentDiscount',
					label: this.FormMSG(1033, 'Discount') + '(%)',
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'vat',
					label: this.FormMSG(1032, 'VAT'),
					formatter: (value) => value,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'totalBudget',
					label: this.FormMSG(1062, 'Total budget'),
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'totalBudgetTtc',
					label: this.FormMSG(1063, 'Total budget TTC'),
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'contextMenu',
					label: this.FormMSG(31, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(15, 'Edit'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'delete',
					label: this.FormMSG(16, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];

			return fields;
		},
		sendStatues() {
			return this.FormMenu(1324);
		}
	},

	data() {
		return {
			acceptOfferAndCreateTask: false
		};
	},

	methods: {
		async newOffer() {
			try {
				const today = new Date();
				today.setHours(0, 0, 0);
				// const tomorrow = today.setDate(today.getDate() + 1)
				await updatedTask(0, {
					name: this.FormMSG(1010, 'Default offer'),
					description: '',
					offerDate: new Date().toISOString(),
					offerMade: true,
					totalBudget: 0,
					totalBudgetTtc: 0,
					percentDiscount: 0,
					dayDuration: 1,
					status: 9,
					internalPriceFactor: 1
				}).then((response) => {
					const { taskPlanningId } = response;
					if (this.$route.name === 'administrationOffers') {
						store.state.isAdministrationOffers = true;
					} else {
						store.state.isAdministrationOffers = false;
					}

					this.$router.replace(`/offers/${taskPlanningId}`);
				});
			} catch (e) {
				this.$bvToast.toast(e.message, {
					title: 'Error',
					variant: 'danger',
					solid: true,
					toaster: 'b-toaster-top-center'
				});
			}
		},
		editOffer(item) {
			if (this.$route.name === 'administrationOffers') {
				store.state.isAdministrationOffers = true;
			} else {
				store.state.isAdministrationOffers = false;
			}
			this.$router.push(`/offers/${parseInt(item.taskPlanningId, 10)}`);
		},
		downloadOfferInXls(item) {
			const url = `${process.env.VUE_APP_GQL}/downloadOffer/0/${item.id}`;
			axios({
				url,
				method: 'GET',
				responseType: 'blob',
				headers: {
					Authorization: `Bearer ${store.state.token}`
				}
			})
				.then((response) => {
					// console.log('Response: ', response);
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${generateRandomFilename()}.${dbExtension[response.headers['content-type']].extensions[0]}`);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.$bvToast.toast(this.FormMSG(1022, 'Download successful'), {
						title: this.FormMSG(1021, 'Success'),
						variant: 'success',
						solid: true,
						toaster: 'b-toaster-top-center'
					});
				})
				.catch((error) => {
					// console.log('Error: ', error);
					this.$bvToast.toast(error.message, {
						title: this.FormMSG(1023, 'Error'),
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-center'
					});
				});
			// console.log('Xls format: ', item);
		},
		downloadOfferInDoc(item) {
			// console.log('Doc: ', item);
			if (item.offerDocTemplateXid === '') {
				this.$toast({
					message: this.FormMSG(75, 'Make sure you have uploaded the corresponding template offer.'),
					title: this.FormMSG(17, 'Alert'),
					variant: 'warning',
					delay: 3000
				});
			} else {
				const url = `${process.env.VUE_APP_GQL}/downloadOffer/1/${item.id}`;
				axios({
					url,
					method: 'GET',
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${store.state.token}`
					}
				})
					.then((response) => {
						// console.log('Response: ', response);
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `${generateRandomFilename()}.${dbExtension[response.headers['content-type']].extensions[0]}`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						this.$bvToast.toast(this.FormMSG(1022, 'Download successful'), {
							title: this.FormMSG(1021, 'Success'),
							variant: 'success',
							solid: true,
							toaster: 'b-toaster-top-center'
						});
					})
					.catch((error) => {
						this.$bvToast.toast(error.message, {
							title: this.FormMSG(1023, 'Error'),
							variant: 'danger',
							solid: true,
							toaster: 'b-toaster-top-center'
						});
					});
			}
			// alert('Coming soon Doc format');
			// console.log('Doc format: ', item);
		},
		downloadOfferInPdf(item) {
			this.$toast({
				message: 'Coming soon!',
				title: 'Info',
				variant: 'info',
				delay: 3000
			});
			const url = `${process.env.VUE_APP_GQL}/downloadOffer/2/${item.id}`;
			// console.log('Pdf format: ', item);
		},
		async handleClickAccepted(task) {
			const el = this.$createElement;
			const self = this;
			const message = el('div', { class: ['d-inline'] }, [
				el('h5', { class: ['pb-2 text-success font-weight-bold'] }, [self.FormMSG(25, 'Once the offer is accepted, you cannot modify it anymore.')]),
				el('div', { class: ['pb-3 lh-22'] }, [
					el(
						'b-form-checkbox',
						{
							class: [''],
							props: {
								size: 'md'
							},
							on: {
								change: function (payload) {
									self.acceptOfferAndCreateTask = payload;
								}
							}
						},
						[self.FormMSG(32, 'Check if you want also generate all corresponding tasks.')]
					)
				]),
				el('div', { class: [''] }, [self.FormMSG(24, 'Are you sure to continue ?')])
			]);

			this.$bvModal
				.msgBoxConfirm([message], {
					title: this.FormMSG(33, 'Confirm accepting offer'),
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'primary',
					okTitle: this.FormMSG(26, 'YES'),
					cancelTitle: this.FormMSG(27, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (r) => {
					if (r) {
						await acceptedOffer(task.id).then(async (ra) => {
							if (ra) {
								this.$emit(EMITTER_RELOAD_DATA);
								this.$bvToast.toast(this.FormMSG(34, `Offer status was modified to "accepted"`), {
									title: this.FormMSG(1021, 'Success'),
									variant: 'success',
									solid: true,
									toaster: 'b-toaster-top-center'
								});

								if (this.acceptOfferAndCreateTask) {
									await createTaskOffer(task.id)
										.then(async (rc) => {
											if (rc) {
												this.$emit(EMITTER_RELOAD_DATA);
												this.$bvToast.toast(this.FormMSG(35, `All corresponding tasks were created successfully.`), {
													title: this.FormMSG(1021, 'Success'),
													variant: 'success',
													solid: true,
													toaster: 'b-toaster-top-center'
												});
											}
										})
										.catch((e) => {
											this.$bvToast.toast(this.FormMSG(36, `All corresponding tasks were already created.`), {
												title: this.FormMSG(72, 'Warning'),
												variant: 'warning',
												solid: true,
												toaster: 'b-toaster-top-center'
											});
										});
								}
							}
						});
					}
					this.acceptOfferAndCreateTask = false;
				});
		},
		async handleRejectOffer(task) {
			// console.log('handleRejectOffer: ', task);
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(24, 'Are you sure to continue ?'), {
					title: this.FormMSG(69, 'Confirm rejecting offer'),
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(26, 'YES'),
					cancelTitle: this.FormMSG(27, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					await updatedTask(task.id, {
						sendStatus: 0, // TODO add 'rejected' status
						taskPlanningId: task.taskPlanningId
					}).then(async (res) => {
						if (res) {
							this.$emit(EMITTER_RELOAD_DATA);
							this.$bvToast.toast(this.FormMSG(44, `Offer rejected successfully.`), {
								title: this.FormMSG(1021, 'Success'),
								variant: 'success',
								solid: true,
								toaster: 'b-toaster-top-center'
							});
						}
					});
				});
		},
		async handleCopyOffer(task) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(24, 'Are you sure to continue ?'), {
					title: this.FormMSG(70, 'Confirm copying offer'),
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'success',
					okTitle: this.FormMSG(26, 'YES'),
					cancelTitle: this.FormMSG(27, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					await copyOffer(task.id)
						.then(async (res) => {
							if (res) {
								this.$emit(EMITTER_RELOAD_DATA);
								this.$bvToast.toast(this.FormMSG(37, `Another offer was created successfully.`), {
									title: this.FormMSG(1021, 'Success'),
									variant: 'success',
									solid: true,
									toaster: 'b-toaster-top-center'
								});
							}
						})
						.catch((e) => {
							// console.log('handleCopyOffer error: ', e);
							this.$bvToast.toast(this.FormMSG(38, `An error occurred during copy of offer.`), {
								title: this.FormMSG(1023, 'Error'),
								variant: 'danger',
								solid: true,
								toaster: 'b-toaster-top-center'
							});
						});
				});
		},
		async handleVariantOffer(task) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(24, 'Are you sure to continue ?'), {
					title: this.FormMSG(71, 'Confirm creating a new variant offer'),
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'success',
					okTitle: this.FormMSG(26, 'YES'),
					cancelTitle: this.FormMSG(27, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					await copyOffer(task.id, true)
						.then(async (res) => {
							if (res) {
								this.$emit(EMITTER_RELOAD_DATA);
								this.$bvToast.toast(this.FormMSG(39, `A new variant of offer was created successfully.`), {
									title: this.FormMSG(1021, 'Success'),
									variant: 'success',
									solid: true,
									toaster: 'b-toaster-top-center'
								});
							}
						})
						.catch((e) => {
							console.log('handleVariantOffer error: ', e);
							this.$bvToast.toast(this.FormMSG(40, `An error occurred during create a new variant of offer.`), {
								title: this.FormMSG(1023, 'Error'),
								variant: 'danger',
								solid: true,
								toaster: 'b-toaster-top-center'
							});
						});
				});
		},
		async handleCreateTask(task) {
			await createTaskOffer(task.id)
				.then(async (rc) => {
					if (rc) {
						this.$emit(EMITTER_RELOAD_DATA);
						this.$bvToast.toast(this.FormMSG(35, `All corresponding tasks were created successfully.`), {
							title: this.FormMSG(1021, 'Success'),
							variant: 'success',
							solid: true,
							toaster: 'b-toaster-top-center'
						});
					}
				})
				.catch((e) => {
					this.$bvToast.toast(this.FormMSG(36, `All corresponding tasks were already created.`), {
						title: this.FormMSG(72, 'Warning'),
						variant: 'warning',
						solid: true,
						toaster: 'b-toaster-top-center'
					});
				});
		}
	}
};
